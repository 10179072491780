:root{
  --min-width: 200px
}

.form{
  border:1px dotted #892332
}

.cover{
  display:flex;
  justify-content:center
}

body{
  margin:0;
  background-color:#fff5ee
}

div{
  box-sizing:border-box
}

p{
  overflow-wrap:break-word
}

a{
  color:#444
}

#redwood-app{
  display:flex;
  place-content:center
}

.active{
  border:1px solid red
}

.app{
  display:flex;
  flex-wrap:wrap;
  place-content:flex-start space-evenly;
  flex-direction:column;
  align-items:center
}

.app__content{
  flex:2 2 0;
  margin-top:1rem
}

.footer{
  display:flex;
  flex:2 2 0;
  padding:2rem;
  bottom:0;
  background-color:#d6ab67
}

.footer__foot{
  display:flex;
  flex-direction:column;
  flex:2 2 0
}

.appbar.MuiAppBar-root{
  display:flex;
  flex:2 2 0;
  align-items:center
}

.appbar.MuiAppBar-root .logo{
  margin:0 1rem
}

.appbar.MuiAppBar-root .old__appbar__menu{
  display:flex
}

@media (orientation: portrait){
  .appbar.MuiAppBar-root .old__appbar__menu{
    flex-wrap:wrap;
    -webkit-padding-start:1rem;
            padding-inline-start:1rem;
    -webkit-padding-end:1rem;
            padding-inline-end:1rem
  }
}

.appbar.MuiAppBar-root .old__appbar__menu>*{
  flex:1 100%
}

@media (orientation: portrait){
  .appbar.MuiAppBar-root .old__appbar__menu>*{
    margin:0 0 0.5rem 0;
    background-color:#fff5ff;
    border:1px dotted #2b6cb0
  }
}
