:root{
  --min-width: 200px
}

.form{
  border:1px dotted #892332
}

.cover{
  display:flex;
  justify-content:center
}

body{
  margin:0;
  background-color:#fff5ee
}

div{
  box-sizing:border-box
}

p{
  overflow-wrap:break-word
}

a{
  color:#444
}

#redwood-app{
  display:flex;
  place-content:center
}

.active{
  border:1px solid red
}

.Dialer{
  position:fixed
}

.Dialer__Dialog{
  display:flex;
  flex-direction:column
}
