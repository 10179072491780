:root{
  --min-width: 200px
}

.form{
  border:1px dotted #892332
}

.cover{
  display:flex;
  justify-content:center
}

body{
  margin:0;
  background-color:#fff5ee
}

div{
  box-sizing:border-box
}

p{
  overflow-wrap:break-word
}

a{
  color:#444
}

#redwood-app{
  display:flex;
  place-content:center
}

.active{
  border:1px solid red
}

:root{
  --rateWidth: calc(var(--min-width) / 6);
  --descriptionWidth: calc(var(--min-width) - var(--rateWidth))
}

@media (orientation: portrait){
  .rating{
    width:var(--min-width)
  }

  .rating__rates{
    width:var(--rateWidth);
    flex-flow:column-reverse nowrap
  }

  .rating__ratesContainer{
    flex-flow:unset
  }
}

.rating{
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content
}

.rating__ratesContainer{
  display:flex;
  flex-flow:row wrap
}

.rating__rates{
  flex:1 0 1;
  display:flex;
  flex-wrap:nowrap
}

.rating__rates .avatar{
  padding:unset
}

.rating__rateDescription{
  flex:2 1;
  min-width:var(--descriptionWidth);
  justify-self:stretch;
  height:3rem
}
